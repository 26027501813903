import React from 'react';

import { useIntl } from 'gatsby-plugin-react-intl';
import { graphql } from 'gatsby';
import { NewsRoomTemplate } from '../templates/newsroom.template';
import { TranslationData } from '../pages/newsroom';
import SEO from '../components/seo';
import { parseLocale } from '../locale';
import { mapNewsroomByCategoryQueryToNewsroomProps } from '../data-mappers/newsroom.mapper';
import { NewsroomByCategoryQuery } from '../../graphql-types';
import { useLocalizedSlugs } from '../hooks/useLocalizedSlugs';

type NewsroomPageProps = {
  data: NewsroomByCategoryQuery;
  pageContext: {
    id: string;
  };
};

const NewsroomPage: React.FC<NewsroomPageProps> = ({ data, pageContext }) => {
  const { cms } = data;
  const intl = useIntl();
  const locale = parseLocale(intl.locale);

  useLocalizedSlugs({
    slugs: [
      { locale: 'en', slug: 'newsroom' },
      { locale: 'fr', slug: 'articles' },
    ],
  });

  const translation: TranslationData = {
    locale: locale,
    load_more: intl.formatMessage({ id: 'load_more' }),
    read_more: intl.formatMessage({ id: 'read_more' }),
    go_to_news: intl.formatMessage({ id: 'go_to_news' }),
  };

  const newsroomProps = mapNewsroomByCategoryQueryToNewsroomProps(cms, translation, pageContext.id);
  const seoProps = {
    title: cms?.newsroom?.title ?? '',
    description: cms?.newsroom?.metas?.description ?? '',
    lang: locale,
  };

  return (
    <>
      <SEO {...seoProps} />
      {newsroomProps && <NewsRoomTemplate {...newsroomProps} />}
    </>
  );
};

export const query = graphql`
  query NewsroomByCategory($locale: String, $id: ID!) {
    cms {
      newsroom(locale: $locale) {
        ...NewsroomContent
      }

      contentCategories(sort: "created_at:desc", locale: $locale) {
        ...NewsCategory
        articlesCount
      }

      contentCategory(id: $id) {
        highlighted_article {
          ...ArticleWithThumbnail
        }
        articles(sort: "created_at:desc") {
          ...ArticleWithThumbnail
        }
      }
    }
  }
`;

export default NewsroomPage;
